import tw from "twin.macro";
import { useState } from "react";

import {
    Button,
    DropdownField,
    ErrorText,
    Label,
    PhoneField,
    RadioField,
    SimpleTile,
    TextField,
} from "@clevero/components";
import { VscAdd } from "react-icons/vsc";

import { FieldWrapper, FieldGroup } from "../../../Components.styled";
import { bookingFormSchema, validateForm } from "../../../../Utility/schema.validator";
import { toast } from "react-toastify";

const BookingForm = props => {
    //fields and its initial values
    const initialState = {
        "first-name": "",
        "lead-guest": false,
        "last-name": "",
        "email-address": "",
        gender: "",
        "phone-number": "",
        "price-type": "",
    };

    //field-value object
    const [fieldValues, setFieldValues] = useState(initialState);
    const [formError, setFormError] = useState({});
    const [alert, setAlert] = useState(false);
    //on guest add
    async function onAddGuest() {
        const { error } = await validateForm(fieldValues, bookingFormSchema).catch(err => {
            setFormError(err);
            return { error: true };
        });

        if (error) return;

        // check for tour spots remaining
        const guestSpotsRemaining = +props.location.state.eventDetails["guest-spots-remaining"] || 12;
        if (props.guestsArray.length >= guestSpotsRemaining) {
            setAlert(true);
            setFieldValues(initialState);

            setTimeout(() => {
                setAlert(false);
            }, 10000);

            return toast.info(`Guests limit reached for this tour!`);
        }

        //no form error
        props.addGuest({ ...fieldValues, "lead-guest": props.guestsArray.length ? false : true });
        setFieldValues(initialState);
    }

    const fieldStyle = {
        background: "#F4F5F7",
        borderWidth: 0.6,
        borderFocus: "#62BA38",
        backgroundFocus: "none",
    };

    function onChangeHandler(e) {
        setFieldValues({ ...fieldValues, [e.name]: e.value });
    }

    function onFocusHandler(e) {
        setFormError(errObj => ({ ...errObj, [e.name]: null }));
    }

    const commonFieldProps = {
        onChange: onChangeHandler,
        onFocus: onFocusHandler,
        autoComplete: "off",
        fieldStyle,
        color: "#62BA38",
    };

    const Alert = tw.div`p-2 bg-indigo-100 text-black mb-4 border-radius[8px] `;

    const Alink = tw.a`text-decoration[underline] font-bold`;

    return (
        <>
            {alert && (
                <Alert style={{ boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px` }}>
                    Please call (02) 9975 4553 to see if we can book your additional guest/s into this tour,
                    alternatively, find another available date
                    <Alink href="https://bookings.lifesanadventure.com.au">here</Alink>
                </Alert>
            )}
            <SimpleTile innerStyle={{ padding: "min(5vw, 2rem)" }}>
                <FieldGroup>
                    <FieldWrapper>
                        <Label label="First Name" name={"first-name"} mandatory />
                        <TextField
                            name={"first-name"}
                            value={fieldValues["first-name"]}
                            error={formError["first-name"]}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["first-name"]} errorText={formError["first-name"]} />
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label label="Last Name" name={"last-name"} mandatory />
                        <TextField
                            name={"last-name"}
                            value={fieldValues["last-name"]}
                            error={formError["last-name"]}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["last-name"]} errorText={formError["last-name"]} />
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label label="Email Address" name={"email-address"} mandatory />
                        <TextField
                            name={"email-address"}
                            value={fieldValues["email-address"]}
                            error={formError["email-address"]}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["email-address"]} errorText={formError["email-address"]} />
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label label="Gender" name={"gender"} mandatory />
                        <RadioField
                            name={"gender"}
                            value={fieldValues["gender"]}
                            error={formError["gender"]}
                            options={props.gender
                                ?.map(({ id, value }) => ({
                                    label: value,
                                    value: id,
                                }))
                                .filter(({ label }) => label.toLowerCase() !== "other")}
                            isLoading={!props.gender}
                            loadingLines={3}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["gender"]} errorText={formError["gender"]} />
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label label="Phone Number" name={"phone-number"} mandatory />
                        <PhoneField
                            name={"phone-number"}
                            value={fieldValues["phone-number"]}
                            error={formError["phone-number"]}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["phone-number"]} errorText={formError["phone-number"]} />
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label label="Price Type" name={"price-type"} mandatory />
                        <DropdownField
                            name={"price-type"}
                            value={fieldValues["price-type"]}
                            error={formError["price-type"]}
                            options={props.priceTypes?.map(({ value }) => ({
                                label: value,
                                value: value,
                            }))}
                            isLoading={!props.priceTypes}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["price-type"]} errorText={formError["price-type"]} />
                    </FieldWrapper>

                    {/* <FieldWrapper>
                        <Label label="I am the booking organiser" name={"lead-guest"} />
                        <RadioField
                            name={"lead-guest"}
                            value={fieldValues["lead-guest"]}
                            error={formError["lead-guest"]}
                            options={[
                                { label: "Yes", value: true, color: "#639A33" },
                                { label: "No", value: false, color: "#FF0000" },
                            ]}
                            type="button"
                            isClearable={false}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["lead-guest"]} errorText={formError["lead-guest"]} />
                    </FieldWrapper> */}
                </FieldGroup>
                <Button
                    onClick={() => onAddGuest()}
                    color={!props.guestsArray.length ? "#62BA38" : "#FC875D"}
                    variant="solid"
                    buttonStyle={{ borderWidth: 1 }}
                    disabled={props.disableBtn}
                    style={{ margin: "1rem 0 0 auto" }}
                >
                    <VscAdd />
                    &nbsp;{!props.guestsArray.length ? "Add to Booking" : "Add Another Guest"}
                </Button>
            </SimpleTile>
        </>
    );
};

export default BookingForm;
