/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";
import { useTable } from "react-table";

const TableWrapper = tw.div`overflow-y-auto my-8 py-3`;
const Table = tw.table`table-auto text-sm text-center w-full whitespace-nowrap`;
const Thead = tw.thead`bg-[#F6FBF3] text-gray-500`;
const Tbody = tw.tbody`text-gray-600`;
const Tr = styled.tr(({ even }) => [
  tw`border-[1px] border-gray-400`,
  even && tw`bg-[#E0F1D7]`,
]);
const Th = tw.th`font-semibold py-4 px-2`;
const Td = tw.td`font-light py-4 px-2`;
const TdTotal = tw.td`border-[1px] border-gray-400 font-bold text-LAA-primary-dim px-2 py-4 bg-[#F7F8FA]`;
const TdTotalHead = tw.td`text-gray-500 font-medium text-right pr-6`;

const TableComponent = (props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
    },
    props.tableHooks
  );

  const isEven = (idx) => idx % 2 !== 0;

  return (
    <TableWrapper>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} even={isEven(i)}>
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                })}
              </Tr>
            );
          })}

          {rows.length === 0 &&
            headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {/*  {headerGroup.headers.map((column) => ( */}
                <Td colSpan={headerGroup.headers.length}>
                  <div tw="text-sm text-center text-gray-400">
                    -- No Guest added yet --
                  </div>
                </Td>
                {/* ))} */}
              </Tr>
            ))}
        </Tbody>

        <tfoot>
          {footerGroups.map((footerGroups) => (
            <tr {...footerGroups.getFooterGroupProps()}>
              {footerGroups.headers.map((column) =>
                column.id === "deposit-required" || column.id === "total" ? (
                  <TdTotal {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </TdTotal>
                ) : (
                  <TdTotalHead {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </TdTotalHead>
                )
              )}
            </tr>
          ))}
        </tfoot>
      </Table>
    </TableWrapper>
  );
};

export default TableComponent;
