import tw from "twin.macro";
// import { toast } from "react-toastify";

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { postPayment } from "../../../../api/tours.api";
import Loader from "../../../Loader";

import { IconContext } from "react-icons";
import { BsCheck2Circle } from "react-icons/bs";
import { ImCross } from "react-icons/im";

const MainContainer = tw.main`h-60 w-screen flex items-center justify-center`;
const CenterDiv = tw.div`max-w-sm p-8 flex flex-col gap-4 items-center justify-center text-base text-gray-600 shadow-lg rounded-md text-center font-medium`;

const msg = {
    success: "Payment success! Your data has been registered into our system.",
    error: "An error occurred while processing the card. Please try again, or contact Life's An Adventure to organise payment.",
};

const Success = () => {
    const [searchParams] = useSearchParams();
    const charge_token = searchParams.get("charge_token");
    const leadBookedGuestId = searchParams.get("leadBookedGuestId");
    const amount = searchParams.get("amount");
    const bookingId = searchParams.get("bookingId");
    const isVoucher = searchParams.get("isVoucher");
    const giftVoucherId = searchParams.get("giftVoucherId");
    const depositRequired = searchParams.get("depositRequired");
    const bookedGuestIds = searchParams.get("bookedGuestIds");
    const [loading, setLoading] = useState(true);
    const [apiErr, setApiErr] = useState(false);

    useEffect(() => {
        (async () => {
            // const toastId = toast.loading("Please wait...");
            let payload = null;
            if (isVoucher) {
                payload = {
                    amountPaid: amount,
                    chargeToken: charge_token,
                    voucher: {
                        isVoucherPayment: true,
                        voucherId: giftVoucherId,
                    },
                };
            } else {
                payload = {
                    amountPaid: amount,
                    chargeToken: charge_token,
                    bookingId: bookingId,
                    leadBookedGuest: ["" + leadBookedGuestId],
                    guests: JSON.parse(bookedGuestIds).map((id, index) => {
                        return {
                            "deposit-required": JSON.parse(depositRequired)[index],
                            id,
                        };
                    }),
                    voucher: {
                        isVoucherPayment: false,
                        voucherId: "",
                    },
                };
            }

            const { error, googleAnalyticsPayload } = await postPayment(payload).catch(err => ({
                error: true,
            }));

            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push(googleAnalyticsPayload);

            if (error) {
                // toast.update(toastId, {
                //     render: msg.error,
                //     type: "error",
                //     isLoading: false,
                // });
                setLoading(false);
                return setApiErr(true);
            }

            // window.parent.postMessage({ channel: "payment-iframe", processCompleted: true });
            // toast.update(toastId, {
            //     render: msg.success,
            //     type: "success",
            //     isLoading: false,
            // });
            // barRef.current.updateToNext();
            setLoading(false);
        })();
    }, [charge_token, leadBookedGuestId, amount, bookingId, isVoucher, giftVoucherId, bookedGuestIds, depositRequired]);

    return (
        <MainContainer id="payment-container">
            {loading ? (
                <CenterDiv>
                    <Loader /> Please wait...
                </CenterDiv>
            ) : !apiErr ? (
                <CenterDiv>
                    <IconContext.Provider value={{ color: "#62BA38", size: "3.5rem" }}>
                        <div>
                            <BsCheck2Circle />
                        </div>
                    </IconContext.Provider>

                    <p>{msg.success}</p>
                </CenterDiv>
            ) : (
                <CenterDiv>
                    <IconContext.Provider value={{ color: "#FF0000", size: "3rem" }}>
                        <div>
                            <ImCross />
                        </div>
                    </IconContext.Provider>
                    <p>{msg.error}</p>
                </CenterDiv>
            )}
        </MainContainer>
    );
};

export default Success;
