import tw from "twin.macro";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

import { Label, TextField } from "@clevero/components";
import { validateJoinCode } from "../../../../api/tours.api";

const Section = tw.section`flex gap-2 w-full mt-8 mb-4 px-4 flex-col items-start sm:(flex-row items-center)`;
const MsgContainer = tw.div`p-4 mt-4 mb-8 text-gray-500 bg-gray-100`;
const LabelWrapper = tw.div`whitespace-nowrap`;
const FieldWrapper = tw.div`w-56`;
const ToolTip = tw.div`max-w-xs`;
const Button = tw.button`text-center whitespace-nowrap px-6 py-1 bg-LAA-primary text-white rounded-[4px] disabled:opacity-50 self-auto sm:self-stretch`;

const BookingId = (props) => {
  const [bookingId, setBookingId] = useState("");
  const [bookingResMsg, setBookingResMsg] = useState(null);
  const { validBookingId, setValidBookingId, btnText, setBtnText } =
    useOutletContext();

  async function handleClick() {
    if (!bookingId) return;

    const validationStatus = await toast
      .promise(() => validateJoinCode(bookingId), {
        pending: "Please wait",
        success: {
          render({ data }) {
            if (data?.mssg) setBookingResMsg(data.mssg);
            return data?.mssg || "Success";
          },
          icon: "✅",
        },
        error: {
          render({ data }) {
            return data?.data.mssg || "An error occurred";
          },
        },
      })
      .catch((err) => err.data);

    if (validationStatus.validatedBookingId) {
      setBtnText("Booking Id validated");
      setValidBookingId(validationStatus.validatedBookingId);
    }

    setBookingId("");
  }

  const fieldStyle = {
    background: "#F4F5F7",
    borderWidth: 0.6,
    borderFocus: "#62BA38",
    backgroundFocus: "none",
  };

  return (
    <>
      <Section>
        <LabelWrapper>
          <Label
            label="Joining someone?"
            description={
              <ToolTip>
                Got a friend booked in for the same tour?
                <br /> Enter their Booking ID to help us group you.
              </ToolTip>
            }
          />
        </LabelWrapper>

        <FieldWrapper>
          <TextField
            value={bookingId}
            onChange={(e) => setBookingId(e.value)}
            fieldStyle={fieldStyle}
            disabled={!!validBookingId}
          />
        </FieldWrapper>

        <Button
          onClick={handleClick}
          disabled={!bookingId || !!validBookingId || props.disableBtn}
        >
          {btnText}
        </Button>
      </Section>
      {bookingResMsg && <MsgContainer>{bookingResMsg}</MsgContainer>}
    </>
  );
};

export default BookingId;
