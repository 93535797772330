import tw from "twin.macro";
import { useLocation } from "react-router-dom";
import { AvailabilityStatus, EventStartEnd } from "../Components.styled";

const Section = tw.section`px-2 my-6`;
const Header = tw.div`flex justify-center items-center my-4 gap-4 text-4xl font-bold text-gray-600`;
const Details = tw.div`max-w-2xl m-auto py-2`;

const BookingHeader = () => {
  const location = useLocation();
  return (
    <Section>
      <Header>
        {location.state?.parsedEventDetails.productName}
        <AvailabilityStatus
          color={location.state?.parsedEventDetails.colorCode}
          renderComponent={!!location.state?.parsedEventDetails.tag}
          bookingHeader
        >
          {location.state?.parsedEventDetails.tag}
        </AvailabilityStatus>
      </Header>

      <Details>
        <EventStartEnd data={location.state?.parsedEventDetails} />
      </Details>
    </Section>
  );
};

export default BookingHeader;
