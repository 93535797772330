/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { forwardRef, useImperativeHandle, useState } from "react";
import ReactDom from "react-dom";

import { IoClose } from "react-icons/io5";

const ModalBackdrop = tw.div`fixed top-0 left-0 right-0 bottom-0 z-30 bg-black bg-opacity-50 backdrop-blur-sm`;
const CenterModal = tw.div`fixed top-3 left-1/2 -translate-x-1/2 z-40 rounded-3xl shadow-[rgba(0, 0, 0, 0.35) 0px 5px 15px]`;
const ItemWrapper = tw.div`absolute w-min top-4 right-4 z-50 opacity-50 text-3xl cursor-pointer hover:(opacity-80 scale-110)`;

const Modal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      open,
      close,
    };
  });

  const open = () => {
    document.body.style.overflow = "hidden";
    setDisplay(true);
  };

  const close = () => {
    document.body.style.overflow = "unset";
    if (props.onClose) props.onClose();
    setDisplay(false);
  };

  if (!display) return null;

  return ReactDom.createPortal(
    <>
      <ModalBackdrop onClick={!props.backdropEvent ? close : () => {}} />
      <CenterModal>
        <ItemWrapper onClick={close} title="Close">
          <IoClose />
        </ItemWrapper>
        {props.children}
      </CenterModal>
    </>,
    document.getElementById("modal")
  );
});

export default Modal;
