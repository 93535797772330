import React, { useEffect, useState } from "react";
import { getSelectData, getProductByUrl } from "../../api/tours.api";
import { useLocation } from "react-router-dom";
import Loader from "../Loader";
import Form from "./components/Form";
import tw from "twin.macro";

const CenterDiv = tw.div`h-[100%] flex items-center justify-center`;

function GiftVoucherMain() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [voucherTypeOptions, setVoucherTypeOptions] = useState([]);
    const url = location?.state ? location.state.url : null;

    useEffect(() => {
        let isFetching = true;
        setLoading(true);
        try {
            (async function () {
                if (isFetching) {
                    let productResponse = null;
                    if (url) {
                        productResponse = await getProductByUrl(url);
                    } else {
                        productResponse = await getSelectData("life-products");
                    }
                    setProduct(productResponse);
                    const voucherTypeOptions = await getSelectData("laa-gift-voucher-types");
                    setVoucherTypeOptions(voucherTypeOptions);
                    if (productResponse) setLoading(false);
                }
            })();
        } catch (err) {
            console.error(err);
        }
        return () => {
            isFetching = false;
        };
    }, [url]);
    return loading ? (
        <CenterDiv>
            <Loader />
        </CenterDiv>
    ) : (
        <Form product={product} voucherTypeOptions={voucherTypeOptions} isProductVoucher={!!url} />
    );
}

export default GiftVoucherMain;
