import * as yup from "yup";

export async function validateForm(fieldValues, schema) {
    try {
        //validate guest details
        await schema.validate(fieldValues, {
            abortEarly: false,
        });

        return { error: false };
    } catch (err) {
        //if form error
        const errObj = err.inner.reduce((prev, curr) => ({ ...prev, [curr.path]: curr.message }), {});

        throw errObj;
    }
}

export let bookingFormSchema = yup.object().shape({
    "first-name": yup.string().trim().required("Name is required"),
    "lead-guest": yup.boolean().typeError("Select one of the above option"),
    "last-name": yup.string().trim().required("Full name is required"),
    "email-address": yup.string().trim().email("Please enter a valid email").required("Email is required"),
    gender: yup.string().trim().required("Please select a gender"),
    "phone-number": yup.string().trim().required("Phone number is required"),
    "price-type": yup.string().trim().required("Please select a price type"),
});

export let cardSchema = yup.object().shape({
    description: yup.string().trim().required("Payment description is required"),
    email: yup.string().trim().email("Please enter a valid email").required("Email is required"),
    card_number: yup.string().matches("^[0-9]{16}$", "Invalid card number").required("Card number missing"),
    expiry_date: yup
        .date()
        .typeError("Invalid date format")
        .min(new Date(), "Invalid expiry date")
        .required("Expiry date is required"),
    cvc: yup.string().matches("^[0-9]{3,4}$", "Invalid CVC").required("CVC is missing"),
    first_name: yup.string().trim().required("Name is required"),
    last_name: yup.string().trim().required("Full name is required"),
    address_line1: yup.string().trim().required("Address is required"),
    address_city: yup.string().trim().required("City is required"),
    address_country: yup.string().trim().required("Country is required"),
});
