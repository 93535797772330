import tw from "twin.macro";
import { createSearchParams, useLocation } from "react-router-dom";

const Iframe = tw.iframe`m-auto my-8 border-2 border-gray-200 rounded-xl w-full `;

const VoucherPayment = () => {
    const location = useLocation();

    const { amount, purchaserEmail, productName, isVoucher, giftVoucherId } = location.state;
    const successParams = createSearchParams({
        amount,
        purchaserEmail,
        productName,
        isVoucher,
        giftVoucherId,
    });

    const queryParams = createSearchParams({
        amount,
        description: `Payment for voucher of Life's and adventure for ${location.state.productName}`,
        amount_editable: false,
        success_url:
            process.env.NODE_ENV === "development"
                ? `http://localhost:3000/success?${successParams}`
                : `${process.env.REACT_APP_SUCCESS_URL}/success?${successParams}`,
        email: purchaserEmail,
    });

    const url =
        process.env.NODE_ENV === "development"
            ? `https://pay.pinpayments.com/rlvl/sc/test?${queryParams}`
            : `${process.env.REACT_APP_PAYMENT_URL}?${queryParams}`;

    return <Iframe src={url} height={1200} />;
};

export default VoucherPayment;
