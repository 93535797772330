import tw from "twin.macro";
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { DropdownField, Label, SimpleTile, TextField } from "@clevero/components";
import Modal from "../../../Modal";
import { applyCouponCode } from "../../../../api/tours.api";
import toCurrency from "../../../../Utility/toCurrency";

const Section = tw.section`flex gap-4 w-full my-6 px-4 flex-col`;
const InputWrapper = tw.div`flex gap-2 flex-col items-start sm:(flex-row items-center)`;
const LabelWrapper = tw.div`whitespace-nowrap`;
const FieldWrapper = tw.div`w-60`;
const Button = tw.button`text-center whitespace-nowrap px-6 py-1 bg-LAA-primary text-white rounded-[4px] disabled:opacity-50 self-auto sm:self-stretch`;

const DiscountCode = props => {
    const location = useLocation();
    const ModalRef = useRef(null);

    const [discountCode, setDiscountCode] = useState("");
    const [discountEmail, setDiscountEmail] = useState("");
    const [balanceRemaining, setBalanceRemaining] = useState(null);
    const [guestsWithRemainingTotal, setGuestsWithRemainingTotal] = useState([]);

    function getPayload({ email = "" }) {
        return {
            code: discountCode,
            productId: location.state.eventDetails.product.id,
            email,
            amountWithPriceType: props.discountedGuestsArray.map(guest => ({
                amount: guest.total,
                priceType: guest["price-type"].toLowerCase().replaceAll(" ", "-"),
                email: guest["email-address"],
            })),
        };
    }

    function updateGuestArray(response) {
        const updatedGuestArr = response.updatedPricesAfterDiscount.map(guest => {
            const orgGuestObj = props.discountedGuestsArray.find(orgGuest => {
                return orgGuest["email-address"] === guest.email;
            });

            const discountValue =
                orgGuestObj.discountValue & guest.discountValue
                    ? orgGuestObj.discountValue + guest.discountValue
                    : orgGuestObj.discountValue || guest.discountValue
                    ? orgGuestObj.discountValue || guest.discountValue
                    : 0;

            if (
                orgGuestObj["price-type"] === "Normal Price" &&
                +guest.amount > (+location.state.eventDetails.product["deposit-required"] || 200)
            )
                return {
                    ...orgGuestObj,
                    "deposit-required": +location.state.eventDetails.product["deposit-required"] || 200,
                    total: guest.amount,
                    discountValue,
                };
            return {
                ...orgGuestObj,
                "deposit-required": guest.amount,
                total: guest.amount,
                discountValue,
            };
        });

        props.setDiscountedGuestsArray(updatedGuestArr);
        setGuestsWithRemainingTotal(
            updatedGuestArr.reduce((prev, curr) => {
                if (curr.total !== 0) return [...prev, curr];
                return prev;
            }, [])
        );
    }

    function responseHandler(response) {
        const codeType = response.codeType.toLowerCase();
        if (codeType === "discount code" || codeType === "promo code") {
            if (codeType === "discount code") {
                props.setAgentInfo({
                    agentEmail: response.agentEmail,
                    agentId: response.agentId,
                    agentName: response.agentName,
                });
            }
            setDiscountCode("");
            props.setCodeType(codeType);
        } else {
            if (response.balanceRemaining > 0) {
                setBalanceRemaining(response.balanceRemaining);
                if (response.updatedPricesAfterDiscount.reduce((prev, curr) => prev + +curr.amount, 0) > 0) {
                    ModalRef.current.open();
                } else {
                    ModalRef.current.close();
                }
            } else {
                if (ModalRef.current) ModalRef.current.close();
                setDiscountCode("");
            }
        }

        if (codeType !== "discount code") {
            props.setVoucherCodeArr(prev => [...prev, "" + response.voucherId]);
        }
    }

    async function handleClick() {
        if (!discountCode) return;

        const payload = getPayload({ email: discountEmail });

        const response = await toast
            .promise(() => applyCouponCode(payload), {
                pending: "Please wait, Applying voucher code",
                success: {
                    render({ data }) {
                        return data?.mssg || "Success";
                    },
                    icon: "✅",
                    autoClose: false,
                },
                error: {
                    render({ data }) {
                        return data?.data.mssg || "An error occurred";
                    },
                },
            })
            .catch(err => ({ error: true }));

        if (response.error) return props.enableAllBtns();

        updateGuestArray(response);
        responseHandler(response);

        props.enableAllBtns();
        if (response.updatedPricesAfterDiscount.reduce((prev, curr) => prev + +curr.amount, 0) === 0) {
            props.setDisableBtn(true);
            setDiscountCode("");
        }
        props.disableBack();
    }

    const fieldStyle = {
        background: "#F4F5F7",
        borderWidth: 0.6,
        borderFocus: "#62BA38",
        backgroundFocus: "none",
    };

    const commonFieldProps = {
        autoComplete: "off",
        fieldStyle,
        color: "#62BA38",
    };

    function onClose() {
        setDiscountEmail("");
    }

    return (
        <Section>
            <InputWrapper>
                <LabelWrapper>
                    <Label label="Got a Voucher or Discount Code?" name="discountCode" />
                </LabelWrapper>

                <FieldWrapper>
                    <TextField
                        name="discountCode"
                        value={discountCode}
                        onChange={e => setDiscountCode(e.value)}
                        disabled={props.codeType === "discount code" || props.codeType === "promo code"}
                        {...commonFieldProps}
                    />
                </FieldWrapper>

                <Button
                    onClick={handleClick}
                    disabled={
                        !discountCode ||
                        props.codeType === "discount code" ||
                        props.codeType === "promo code" ||
                        props.disableBtn
                    }
                >
                    Apply
                </Button>
            </InputWrapper>

            {props.codeType === "discount code" && (
                <InputWrapper>
                    <LabelWrapper>
                        <Label name="agentEmail" label="Agent Email" />
                    </LabelWrapper>

                    <FieldWrapper>
                        <TextField
                            name="agentEmail"
                            value={props.agentInfo.agentEmail}
                            onChange={e => {
                                console.log("e :", e);
                                props.setAgentInfo(prev => ({ ...prev, agentEmail: e.value }));
                            }}
                            {...commonFieldProps}
                        />
                    </FieldWrapper>
                </InputWrapper>
            )}

            {balanceRemaining && (
                <Modal ref={ModalRef} onClose={onClose} backdropEvent>
                    <SimpleTile
                        innerStyle={{
                            padding: "2rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                        }}
                    >
                        {`Voucher has been applied, You have an available balance of ${toCurrency(
                            balanceRemaining
                        )} remaining. Do you wish to use the remaining discount on a fellow guest?`}
                        <DropdownField
                            name="select guest"
                            value={discountEmail}
                            options={guestsWithRemainingTotal.map(guest => ({
                                label: `${guest["first-name"]} (${guest["email-address"]})`,
                                value: guest["email-address"],
                            }))}
                            onChange={e => setDiscountEmail(e.value)}
                            placeholder={"Select a guest to apply to"}
                            {...commonFieldProps}
                        />
                        <Button color="#62BA38" onClick={handleClick} disabled={!discountEmail}>
                            Share this with other guests
                        </Button>
                        <Button color="#62BA38" onClick={() => ModalRef.current.close()}>
                            Use it for later
                        </Button>
                    </SimpleTile>
                </Modal>
            )}
        </Section>
    );
};

export default DiscountCode;
