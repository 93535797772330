import tw from "twin.macro";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";

import { MultiDropdownField } from "@clevero/components";

import toCurrency from "../../../../Utility/toCurrency";

const Section = tw.section`grid grid-cols-4 my-8`;

const EventContainer = tw.div`col-span-2 border-b-2 border-r-2 p-4 pl-0 flex flex-col gap-2 items-start`;
const Title = tw.div`text-base font-medium text-gray-600`;
const Description = tw.div`text-justify mt-2 text-sm font-light text-gray-600 line-clamp-4`;

const GuestContainer = tw.div`col-span-1 border-b-2 border-r-2 p-4`;
const ChargesContaienr = tw.div`col-span-1 border-b-2 text-gray-600 border-r-2 p-4 text-right`;
const TotalTitle = tw.div`col-span-3 text-right p-4 text-gray-500 font-medium`;
const TotalContainer = tw.div`col-span-1 text-right p-4 font-bold text-LAA-primary-dim`;

const CustomiseTable = props => {
    const location = useLocation();
    const { bookedGuests } = location.state.bookingRes;

    function onChangeHandler(e, variation, capacity) {
        const totalGuestsLength = e.value.length;

        const unitVariationAmount = +variation["fee-adjustment"];
        const isExtraLineAmountBasedOnCapacity = !!capacity;
        const quantity = isExtraLineAmountBasedOnCapacity ? Math.ceil(totalGuestsLength / +capacity) : e.value.length;
        const amount = e.value.length ? quantity * unitVariationAmount : 0;

        props.setFieldValues(prev => ({
            ...prev,
            [e.name]: {
                ...prev[e.name],
                guests: e.value,
                amount,
                variation,
                perGuestAmount: isExtraLineAmountBasedOnCapacity
                    ? amount / totalGuestsLength
                    : unitVariationAmount,
            },
        }));
    }

    const fieldStyle = {
        background: "#F4F5F7",
        borderWidth: 0.6,
        borderFocus: "#62BA38",
        backgroundFocus: "none",
    };

    const commonFieldProps = {
        autoComplete: "off",
        fieldStyle,
        color: "#62BA38",
    };

    return (
        <Section>
            {props.extrasData.map((extra, idx) => (
                <Fragment key={extra.id}>
                    <EventContainer>
                        <Title>{extra.variation.name}</Title>
                        <Description>{extra.variation.description || "No description available"}</Description>
                    </EventContainer>

                    <GuestContainer>
                        <MultiDropdownField
                            name={props.fields[idx][0]}
                            value={props.fieldValues[props.fields[idx][0]].guests}
                            options={bookedGuests.map(guest => ({
                                label: guest["guest-name-text"],
                                value: "" + guest.id,
                            }))}
                            onChange={e => onChangeHandler(e, extra.variation, +extra.variation.capacity)}
                            disabled={props.disableField}
                            {...commonFieldProps}
                        />
                    </GuestContainer>

                    <ChargesContaienr>{toCurrency(props.fieldValues[props.fields[idx][0]].amount)}</ChargesContaienr>
                </Fragment>
            ))}

            <TotalTitle>Grand Total:</TotalTitle>
            <TotalContainer>{toCurrency(props.totalAmount)}</TotalContainer>
        </Section>
    );
};

export default CustomiseTable;
