import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import uniq from "lodash/uniq";
import { toast } from "react-toastify";

import DiscountTable from "./DiscountTable";
import DiscountCode from "./DiscoutCode";
import NavigationButtons from "../../NavigationButtons";

import { createBooking } from "../../../../api/tours.api";

const DiscountsMain = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const navigationBtn = useRef(null);
    const toastId = useRef(null);
    const { discountedGuestsArray, setDiscountedGuestsArray, disableBtn, setDisableBtn } = useOutletContext();

    const [voucherCodeArr, setVoucherCodeArr] = useState([]);
    const [codeType, setCodeType] = useState(false);
    const [agentInfo, setAgentInfo] = useState({
        agentEmail: "",
        agentId: "",
        agentName: "",
    });

    useEffect(() => {
        const guestsArray = location.state?.guestsArray;
        setDiscountedGuestsArray(
            guestsArray?.map(guest => {
                if (guest.isPastPassenger) {
                    const discountedTotal = +guest.total - 100;
                    return {
                        ...guest,
                        "deposit-required":
                            +guest["deposit-required"] > discountedTotal ? +discountedTotal : guest["deposit-required"],
                        total: discountedTotal,
                        discountValue: 100,
                        actualTourAmount: +guest.total,
                    };
                }
                return {
                    ...guest,
                    actualTourAmount: +guest.total,
                };
            })
        );
    }, [location.state, setDiscountedGuestsArray]);

    function disableBack() {
        navigationBtn.current.disableBack();
    }

    function disableAllBtns() {
        navigationBtn.current.disableNavigation();
        setDisableBtn(true);
    }

    function enableAllBtns() {
        navigationBtn.current.enableNavigation();
        setDisableBtn(false);
    }

    function createPayload() {
        const guests = discountedGuestsArray.map(guest => ({
            email: guest["email-address"],
            firstName: guest["first-name"],
            lastName: guest["last-name"],
            phoneNumber: guest["phone-number"],
            gender: ["" + guest.gender],
            exists: guest.exists,
            guestId: guest.guestId,
            leadGuest: guest["lead-guest"],
            priceType: ["" + location.state.priceTypesObj[guest["price-type"]].id],
            tourAmount: +parseFloat(guest.total).toFixed(2),
            discountValue: guest.discountValue ? +parseFloat(guest.discountValue).toFixed(2) : 0,
            actualTourAmount: guest.actualTourAmount,
        }));
        return {
            guests,
            bookingId: location.state.bookingId,
            totalAmount: guests.reduce((prev, curr) => prev + +curr.tourAmount, 0),
            actualTotalTourAmount: guests.reduce((prev, curr) => prev + +curr.actualTourAmount, 0), // tour price without discounts
            couponCode: {
                voucherId: uniq(voucherCodeArr),
                discountValue: guests.reduce((prev, curr) => {
                    return prev + +curr.discountValue;
                }, 0),
                agentId: agentInfo.agentId ? ["" + agentInfo.agentId] : [],
                agencyEmail: agentInfo.agentEmail,
            },
            tourId: ["" + location.state.eventDetails.id],
            tourCode: location.state.eventDetails["tour-code"] || location.state.eventDetails.product["product-code"],
        };
    }

    async function onContinue() {
        disableAllBtns();

        toastId.current = toast("Please wait...", {
            autoClose: false,
            closeButton: false,
            type: toast.TYPE.INFO,
        });

        const payload = createPayload();

        const { apiErr, ...bookingRes } = await createBooking(payload).catch(err => {
            console.log(err);
            return { apiErr: true };
        });

        if (apiErr) {
            enableAllBtns();
            return toast.update(toastId.current, {
                render: "An error occured",
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });
        }

        toast.update(toastId.current, {
            render: "Your booking has been successfully created",
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
        });

        enableAllBtns();
        toast.dismiss(toastId.current);

        navigate("/booking/customise", {
            state: {
                bookingRes: {
                    ...bookingRes,
                    bookedGuests: bookingRes.bookedGuests.map(bg => {
                        const guest = discountedGuestsArray.find(g => g["email-address"] === bg.email) || {};
                        return {
                            ...bg,
                            "deposit-required": +guest["deposit-required"] || 0,
                        };
                    }),
                },
                discountedGuestsArray,
                eventDetails: location.state?.eventDetails,
                parsedEventDetails: location.state?.parsedEventDetails,
            },
        });
    }

    return (
        <>
            <DiscountTable data={discountedGuestsArray} />
            <hr />

            <DiscountCode
                discountedGuestsArray={discountedGuestsArray}
                setDiscountedGuestsArray={setDiscountedGuestsArray}
                setVoucherCodeArr={setVoucherCodeArr}
                codeType={codeType}
                setCodeType={setCodeType}
                agentInfo={agentInfo}
                setAgentInfo={setAgentInfo}
                disableBtn={disableBtn}
                setDisableBtn={setDisableBtn}
                disableBack={disableBack}
                disableAllBtns={disableAllBtns}
                enableAllBtns={enableAllBtns}
            />
            <hr />

            <NavigationButtons ref={navigationBtn} onContinue={onContinue} />
        </>
    );
};

export default DiscountsMain;
