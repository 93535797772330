/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import tw from "twin.macro";

const StyledFrame = tw.iframe`border-2 rounded-lg border-gray-600 my-8 mx-auto resize`;

const Iframe = () => {
  return (
    <StyledFrame
      src="http://localhost:3000/?url=https://www.lifesanadventure.com.au/tours/great-ocean-walk/"
      width="90%"
      height="750"
    />
  );
};

export default Iframe;
