/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BackButton, ContinueButton } from "../Components.styled";

const ButtonContainer = styled.div(({ hide, hasBackBtn }) => [
    tw`flex my-8`,
    hide && tw`hidden`,
    (hasBackBtn && tw`justify-between`) || tw`justify-end`,
]);

const NavigationButtons = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [disableBack, setDisableBack] = useState(false);
    const [showBack] = useState(false);
    const [disableForward, setDisableForward] = useState(false);

    function disableBoth() {
        setDisableBack(true);
        setDisableForward(true);
    }

    function enableBoth() {
        setDisableBack(false);
        setDisableForward(false);
    }

    useImperativeHandle(ref, () => ({
        disableBack: () => setDisableBack(true),
        disableForward: () => setDisableForward(true),
        disableNavigation: () => disableBoth(),
        enableNavigation: () => enableBoth(),
    }));

    return (
        <ButtonContainer hide={location.pathname === "/booking/confirm-&-pay"} hasBackBtn={showBack}>
            {showBack && <BackButton onClick={() => navigate(-1)} disabled={disableBack} />}
            <ContinueButton onClick={props.onContinue} disabled={disableForward} />
        </ButtonContainer>
    );
});

export default NavigationButtons;
