import tw from "twin.macro";
import { useMemo } from "react";
import { CheckboxDisplay, IconButton } from "@clevero/components";

import Table from "../../../Table";
import toCurrency from "../../../../Utility/toCurrency";

const CenterCell = tw.div`m-auto w-min`;
const IconWrapper = tw.div`m-auto flex gap-1 justify-center`;

const GuestTable = (props) => {
  //table columns
  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email-address",
      },
      {
        Header: "First Name",
        accessor: "first-name",
      },
      {
        Header: "Last Name",
        accessor: "last-name",
      },
      {
        Header: "Price Type",
        accessor: "price-type",
        Footer: "Grand Total:",
      },
      {
        Header: "Deposit Required",
        accessor: "deposit-required",
        Cell: (cell) => toCurrency(cell.value),
        Footer: (table) =>
          toCurrency(
            table.rows.reduce(
              (prev, curr) => prev + +curr.values["deposit-required"],
              0
            )
          ),
      },
      {
        Header: "Total (AUD)",
        accessor: "total",
        Cell: (cell) => toCurrency(cell.value),
        Footer: (table) =>
          toCurrency(
            table.rows.reduce((prev, curr) => prev + +curr.values.total, 0)
          ),
      },
    ],
    []
  );

  function tableHooks(hooks) {
    hooks.visibleColumns.push((columns) => [
      {
        id: "Booking Organizer",
        Header: "Booking Organizer",
        Cell: ({ row }) => (
          <CenterCell onClick={() => props.updateLeadGuest(+row.id)}>
            <CheckboxDisplay
              color="#639A33"
              value={row.original["lead-guest"]}
            />
          </CenterCell>
        ),
      },
      ...columns,
      {
        id: "Action",
        Header: "Action",
        Cell: ({ row }) => (
          <IconWrapper>
            <IconButton
              color="#0080fe"
              onClick={() => props.editGuest(+row.id)}
              style={{ marginRight: 0.25 + "rem" }}
              title={"edit"}
            >
              <i className="fa fa-edit" />
            </IconButton>

            <IconButton
              color="#BA3829"
              onClick={() => props.deleteGuest(+row.id)}
              style={{ marginRight: 0.25 + "rem" }}
              title={"delete"}
            >
              <i className="fa fa-trash" />
            </IconButton>
          </IconWrapper>
        ),
      },
    ]);
  }

  const TableProps = {
    columns: columns,
    data: props.data,
    tableHooks: tableHooks,
  };

  return <Table {...TableProps} />;
};

export default GuestTable;
