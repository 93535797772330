import tw from "twin.macro";
import { IconContext } from "react-icons";
import { BsCheck2Circle } from "react-icons/bs";

const CenterDiv = tw.div`max-w-sm p-8 flex flex-col gap-4 mx-auto my-16 items-center justify-center text-base text-gray-600 shadow-lg rounded-md text-center font-medium`;

const NoPaymentSuccess = () => {
  return (
    <CenterDiv>
      <IconContext.Provider value={{ color: "#62BA38", size: "3.5rem" }}>
        <div>
          <BsCheck2Circle />
        </div>
      </IconContext.Provider>

      <p>Your payment has already been made.</p>
    </CenterDiv>
  );
};

export default NoPaymentSuccess;
