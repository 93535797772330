import { useMemo } from "react";

import Table from "../../../Table";
import toCurrency from "../../../../Utility/toCurrency";

const DiscountTable = (props) => {
  //table columns
  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email-address",
      },
      {
        Header: "First Name",
        accessor: "first-name",
      },
      {
        Header: "Last Name",
        accessor: "last-name",
      },
      {
        Header: "Past Passenger Discount",
        accessor: "isPastPassenger",
        Cell: (cell) => (cell.value && toCurrency("100")) || "-",
        Footer: "Grand Total:",
      },
      {
        Header: "Deposit Required",
        accessor: "deposit-required",
        Cell: (cell) => toCurrency(cell.value),
        Footer: (table) =>
          toCurrency(
            table.rows.reduce(
              (prev, curr) => prev + +curr.values["deposit-required"],
              0
            )
          ),
      },
      {
        Header: "Total (AUD)",
        accessor: "total",
        Cell: (cell) => toCurrency(cell.value),
        Footer: (table) => {
          return toCurrency(
            table.rows.reduce((prev, curr) => prev + +curr.values.total, 0)
          );
        },
      },
    ],
    []
  );

  const TableProps = {
    columns: columns,
    data: props.data,
  };

  return <Table {...TableProps} />;
};

export default DiscountTable;
