/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";
import { forwardRef, useImperativeHandle, useState } from "react";

import { ProgressBar, Step } from "react-step-progress-bar";

import { FaCheckCircle } from "react-icons/fa";
import "react-step-progress-bar/styles.css";

//----------------------Styles----------------------//
const ProgressBarContainer = tw.div`hidden max-w-lg m-auto py-12 mb-6 text-sm sm:block`;

const IconWrapper = tw.div`relative`;

const Icon = styled.div(({ active }) => [
  tw`rounded-full h-9 w-9 flex items-center justify-center`,
  (active && tw`bg-LAA-orange text-white`) ||
    tw`bg-white text-gray-500 shadow-md`,
]);

const IconDetail = styled.div(({ active }) => [
  tw`absolute top-12 left-1/2 whitespace-nowrap -translate-x-1/2`,
  (active && tw`text-gray-900`) || tw`text-gray-500`,
]);
//----------------------Styles----------------------//

const ProgressStep = forwardRef((props, ref) => {
  const [progress, setProgress] = useState(1);

  //steps position value should be > 0
  const steps = [
    { details: "Select Tour", position: 1 },
    { details: "Guest Details", position: 25 },
    { details: "Discounts", position: 50 },
    { details: "Customise", position: 75 },
    { details: "Confirm & Pay", position: 100 },
  ];

  useImperativeHandle(ref, () => ({
    updateToNext: nextStep,
    updateToPrev: prevStep,
    toStep,
  }));

  function calculatePercentage() {
    if (progress >= steps.length) return 100;
    return steps[progress].position - 1;
  }

  function getStateIcon(index, position, accomplished) {
    if (accomplished)
      return (
        <FaCheckCircle tw="text-LAA-primary h-9 w-9 bg-white rounded-full" />
      );

    const percent = calculatePercentage();
    return <Icon active={position - percent === 1}>{index + 1}</Icon>;
  }

  function nextStep() {
    if (progress + 1 >= steps.length) return setProgress(steps.length);
    setProgress(progress + 1);
  }

  function prevStep() {
    if (progress - 1 <= -1) return setProgress(0);
    setProgress(progress - 1);
  }

  function toStep(index, route) {
    if (index) return setProgress(index - 1);
    if (route)
      return setProgress(
        steps.findIndex(
          (step) => step.details.toLowerCase().replaceAll(" ", "-") === route
        )
      );
  }

  return (
    <ProgressBarContainer>
      <ProgressBar
        percent={calculatePercentage()}
        filledBackground="linear-gradient(to right, #639A33, #59d61f)"
        height={4}
        stepPositions={steps.map((step) => step.position)}
      >
        {steps.map((step, i) => (
          <Step transition="scale" key={step.position}>
            {({ index, position, accomplished }) => (
              <IconWrapper>
                {getStateIcon(index, position, accomplished)}
                <IconDetail active={position - calculatePercentage() === 1}>
                  {step.details}
                </IconDetail>
              </IconWrapper>
            )}
          </Step>
        ))}
      </ProgressBar>
    </ProgressBarContainer>
  );
});

export default ProgressStep;
