import React from "react";
import {
    TileBody,
    Label,
    ErrorText,
    TextField,
    Button,
    TextAreaField,
    HorizontalInlineLoader,
} from "@clevero/components";
import { FieldGroup, FieldWrapper } from "../../Components.styled";
import tw from "twin.macro";

const ToolTip = tw.div`max-w-xs`;

const CustomerForm = ({
    form,
    debounceEmailHandler,
    formError,
    formHandler,
    recipientEmailErrText,
    purchaserEmailErrText,
    handleSubmit,
    route,
    setRoute,
    submitStatus,
}) => {
    return (
        <TileBody>
            <FieldGroup>
                <FieldWrapper>
                    <Label label="Recipient Email:" name="recipientEmail" mandatory />
                    <TextField
                        name="recipientEmail"
                        value={form["recipientEmail"]}
                        onChange={e => {
                            formHandler(e);
                            debounceEmailHandler(e);
                        }}
                        error={formError["recipientEmail"]}
                    />
                    <ErrorText
                        error={formError["recipientEmail"]}
                        errorText={recipientEmailErrText || `This field is required`}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label label="Recipient First Name:" name="recipientFirstName" mandatory />
                    <TextField
                        name="recipientFirstName"
                        value={form["recipientFirstName"]}
                        onChange={formHandler}
                        error={formError["recipientFirstName"]}
                    />
                    <ErrorText error={formError["recipientFirstName"]} errorText="This field is required" />
                </FieldWrapper>
                <FieldWrapper>
                    <Label label="Recipient Last Name:" name="recipientLastName" mandatory />
                    <TextField
                        name="recipientLastName"
                        value={form["recipientLastName"]}
                        onChange={formHandler}
                        error={formError["recipientLastName"]}
                    />
                    <ErrorText error={formError["recipientLastName"]} errorText="This field is required" />
                </FieldWrapper>
                <FieldWrapper>
                    <Label label="Purchaser Email:" name="purchaserEmail" mandatory />
                    <TextField
                        name="purchaserEmail"
                        value={form["purchaserEmail"]}
                        onChange={e => {
                            formHandler(e);
                            debounceEmailHandler(e);
                        }}
                        error={formError["purchaserEmail"]}
                    />
                    <ErrorText
                        error={formError["purchaserEmail"]}
                        errorText={purchaserEmailErrText || `This field is required`}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label label="Purchaser First Name:" name="purchaserFirstName" mandatory />
                    <TextField
                        name="purchaserFirstName"
                        value={form["purchaserFirstName"]}
                        onChange={formHandler}
                        error={formError["purchaserFirstName"]}
                    />
                    <ErrorText error={formError["purchaserFirstName"]} errorText="This field is required" />
                </FieldWrapper>
                <FieldWrapper>
                    <Label label="Purchaser Last Name:" name="purchaserLastName" mandatory />
                    <TextField
                        name="purchaserLastName"
                        value={form["purchaserLastName"]}
                        onChange={formHandler}
                        error={formError["purchaserLastName"]}
                    />
                    <ErrorText error={formError["purchaserLastName"]} errorText="This field is required" />
                </FieldWrapper>
                <FieldWrapper>
                    <Label
                        label="Message:"
                        name="message"
                        mandatory
                        description={
                            <ToolTip>
                                Leave a message for the recipient <br /> to be delivered along with the voucher.
                            </ToolTip>
                        }
                    />
                    <TextAreaField
                        name="message"
                        value={form["message"]}
                        onChange={formHandler}
                        error={formError["message"]}
                    />
                    <ErrorText error={formError["message"]} errorText="This field is required" />
                </FieldWrapper>
            </FieldGroup>
            <FieldWrapper spaceBetween>
                <Button
                    className="btn"
                    size="md"
                    color="#62BA38"
                    onClick={() => setRoute({ ...route, voucher: true, customer: false })}
                    style={{
                        display: "inline-block",
                    }}
                >
                    Back
                </Button>
                <Button
                    className="btn"
                    size="md"
                    color="#62BA38"
                    onClick={handleSubmit}
                    style={{
                        display: "inline-block",
                    }}
                    disabled={submitStatus}
                >
                    {!submitStatus ? "Get Voucher" : <HorizontalInlineLoader />}
                </Button>
            </FieldWrapper>
        </TileBody>
    );
};

export default CustomerForm;
