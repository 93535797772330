import React, { useState, useEffect, useMemo } from "react";
import { createGiftVoucher, createGuest, checkExistingGuest } from "../../../api/tours.api";
import VoucherForm from "./VoucherForm";
import CustomerForm from "./CustomerForm";
import { Heading, Tile, TileHeader } from "@clevero/components";
import voucherCodes from "voucher-code-generator";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import _ from "lodash";

const Form = ({ product, voucherTypeOptions, isProductVoucher }) => {
    // setting route
    const [route, setRoute] = useState({
        voucher: true,
        customer: false,
    });

    let navigate = useNavigate();

    // setting initial form state
    const [form, setForm] = useState({
        voucherType: null,
        product: null,
        amount: "",
        recipientFirstName: "",
        recipientLastName: "",
        recipientEmail: "",
        message: "",
        purchaserFirstName: "",
        purchaserLastName: "",
        purchaserEmail: "",
    });

    const [formError, setFormError] = useState({});
    const [amountErrText, setAmountErrText] = useState("");
    const [recipientEmailErrText, setRecipientEmailErrText] = useState("");
    const [purchaserEmailErrText, setPurchaserEmailErrText] = useState("");
    const [productItem, setProductItem] = useState([]);

    // submit status
    const [submitStatus, setSubmitStatus] = useState(false);

    // constants
    const productVoucher = "Product Voucher";
    const valueVoucher = "Value Voucher";

    // setting initial form data
    useEffect(() => {
        let isMounted = true;
        if (isMounted && product && voucherTypeOptions) {
            setProductItem(product);
            setForm(prev => ({
                ...prev,
                voucherType: productVoucher,
                product: product && product[0],
                amount: product && product[0] && product[0]["standard-price"],
            }));
        }
        return () => {
            isMounted = false;
        };
    }, [product, voucherTypeOptions]);

    // Fetching existing guest
    const fetchExistingGuest = async e => {
        if (e.name === "recipientEmail" || (e.name === "purchaserEmail" && e.value !== "")) {
            try {
                const existingGuest = await checkExistingGuest({
                    emails: [{ email: e.value }],
                });
                if (existingGuest && existingGuest.guests.length) {
                    const { guest } = existingGuest.guests[0];
                    if (e.name === "recipientEmail" && guest?.exists) {
                        setForm(prevState => {
                            return {
                                ...prevState,
                                recipientEmail: e.value || "",
                                recipientFirstName: guest.details["first-name"] || "",
                                recipientLastName: guest.details["last-name"] || "",
                            };
                        });
                    }
                    if (e.name === "purchaserEmail" && guest?.exists) {
                        setForm(prevState => {
                            return {
                                ...prevState,
                                purchaserEmail: e.value || "",
                                purchaserFirstName: guest.details["first-name"] || "",
                                purchaserLastName: guest.details["last-name"] || "",
                            };
                        });
                    }
                }
            } catch (error) {
                console.error("err", error);
            }
        }
    };

    const debounceEmailHandler = useMemo(e => _.debounce(fetchExistingGuest, 800), []);

    // formHandler function
    const formHandler = e => {
        if (e.name === "voucherType") {
            if (e.value === "Value Voucher") {
                setForm({
                    ...form,
                    [e.name]: e.value,
                    product: {},
                    amount: "",
                });
                return;
            } else {
                setForm({
                    ...form,
                    [e.name]: e.value,
                    product: productItem[0],
                    amount: productItem[0]["standard-price"],
                });
                return;
            }
        }
        if (e.name === "product" && e.value) {
            setForm({
                ...form,
                [e.name]: e.value,
                amount: e.value["standard-price"],
            });
            return;
        }
        setForm({ ...form, [e.name]: e.value });
    };

    const validateEmail = mail => {
        /* eslint-disable-next-line */
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    };

    const handleSubmit = async () => {
        const {
            voucherType,
            product,
            amount,
            recipientFirstName,
            recipientLastName,
            recipientEmail,
            message,
            purchaserFirstName,
            purchaserLastName,
            purchaserEmail,
        } = form;
        if (
            voucherType &&
            product &&
            +amount > 0 &&
            recipientFirstName &&
            recipientLastName &&
            validateEmail(recipientEmail) &&
            message &&
            purchaserFirstName &&
            purchaserLastName &&
            validateEmail(purchaserEmail)
        ) {
            setSubmitStatus(true);
            const type = voucherTypeOptions.find(item => item.value === "Gift Voucher").id;
            const voucherCode = voucherCodes.generate({ length: 6 })[0];
            const giftVoucher = {
                type: type ? [`${type}`] : [],
                product: product?.id ? [`${product.id}`] : [],
                "voucher-amount": +amount,
                message,
                "redemption-code": voucherCode || "",
                "purchased-by": [],
                "purchased-for": [],
                "voucher-name": `${voucherType.split(" ").join("")}-${recipientEmail}-${format(
                    new Date(),
                    "MM/dd/yyyy"
                )}`,
            };

            const recipientInfo = {
                status: [],
                firstName: recipientFirstName,
                lastName: recipientLastName,
                email: recipientEmail,
            };
            const purchaserInfo = {
                status: [],
                firstName: purchaserFirstName,
                lastName: purchaserLastName,
                email: purchaserEmail,
            };

            const emails = [{ email: recipientEmail }, { email: purchaserEmail }];

            try {
                const checkForExistingGuestsResponse = await checkExistingGuest({
                    emails,
                });
                if (checkForExistingGuestsResponse) {
                    const recipientCheckResponse = await checkForExistingGuestsResponse["guests"].find(
                        item => item.email === recipientEmail
                    );
                    const purchaserCheckResponse = await checkForExistingGuestsResponse["guests"].find(
                        item => item.email === purchaserEmail
                    );

                    recipientInfo["existingGuestId"] = recipientCheckResponse.guest.guestId
                        ? recipientCheckResponse.guest.guestId
                        : null;

                    const recipientResponse = await toast
                        .promise(() => createGuest(recipientInfo), {
                            pending: "Please wait, Creating Guest Entry",
                            success: {
                                render({ data }) {
                                    return data?.mssg || "Success";
                                },
                                icon: "✅",
                            },
                            error: {
                                render({ data }) {
                                    return data?.data.mssg || "An error occurred";
                                },
                            },
                        })
                        .catch(err => ({ error: true }));

                    purchaserInfo["existingGuestId"] = purchaserCheckResponse.guest.guestId
                        ? purchaserCheckResponse.guest.guestId
                        : null;

                    const purchaseResponse = await createGuest(purchaserInfo);

                    if (recipientResponse && purchaseResponse) {
                        giftVoucher["purchased-for"] = recipientCheckResponse.guest.exists
                            ? [`${recipientCheckResponse.guest.guestId}`]
                            : [`${recipientResponse.entry.id}`];
                        giftVoucher["purchased-by"] = purchaserCheckResponse.guest.exists
                            ? [`${purchaserCheckResponse.guest.guestId}`]
                            : [`${purchaseResponse.entry.id}`];

                        const giftVoucherResponse = await toast
                            .promise(
                                () =>
                                    createGiftVoucher({
                                        entry: {
                                            ...giftVoucher,
                                        },
                                        subrecords: {},
                                    }),
                                {
                                    pending: "Please wait, Creating Gift Voucher",
                                    success: {
                                        render({ data }) {
                                            return data?.mssg || "Success";
                                        },
                                        icon: "✅",
                                    },
                                    error: {
                                        render({ data }) {
                                            return data?.data.mssg || "An error occurred";
                                        },
                                    },
                                }
                            )
                            .catch(err => ({ error: true }));
                        if (giftVoucherResponse) {
                            setSubmitStatus(false);
                            toast.dismiss();
                            navigate(`/gift-voucher-payment`, {
                                state: {
                                    amount,
                                    purchaserEmail,
                                    productName: Object.keys(product).includes("product-name")
                                        ? product["product-name"]
                                        : "",
                                    isVoucher: true,
                                    giftVoucherId: giftVoucherResponse.entry.id,
                                },
                            });
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            if (recipientEmail !== "" && !validateEmail(recipientEmail)) {
                setRecipientEmailErrText("Invalid email address");
            } else {
                setRecipientEmailErrText("");
            }
            if (purchaserEmail !== "" && !validateEmail(purchaserEmail)) {
                setPurchaserEmailErrText("Invalid email address");
            } else {
                setPurchaserEmailErrText("");
            }
            if (+amount <= 0 && !amount) {
                setAmountErrText("Amount shouldn't be less than 1");
            }
            if (!amount) {
                setAmountErrText("Amount shouldn't be empty");
            }
            setFormError({
                ...formError,
                voucherType: !voucherType,
                product: !product,
                amount: !amount || +amount < 0,
                recipientFirstName: !recipientFirstName,
                recipientLastName: !recipientLastName,
                recipientEmail: recipientEmail !== "" ? !validateEmail(recipientEmail) : !recipientEmail,
                message: !message,
                purchaserFirstName: !purchaserFirstName,
                purchaserLastName: !purchaserLastName,
                purchaserEmail: purchaserEmail !== "" ? !validateEmail(purchaserEmail) : !purchaserEmail,
            });
            return;
        }
        setAmountErrText("");
        setRecipientEmailErrText("");
        setPurchaserEmailErrText("");
        setFormError({});
    };

    const voucherFormHandler = () => {
        const { voucherType, product, amount } = form;
        if (voucherType && product && +amount > 0) {
            setRoute({ ...route, voucher: false, customer: true });
        } else {
            if (+amount <= 0) {
                setAmountErrText("Amount shouldn't be less than 1");
            }
            if (!amount) {
                setAmountErrText("Amount shouldn't be empty");
            }
            setFormError({
                ...formError,
                voucherType: !voucherType,
                product: !product,
                amount: !amount || +amount < 0,
            });
            return;
        }
        setAmountErrText("");
        setFormError({});
    };

    return (
        <Tile
            className="tile"
            style={{
                width: "min(900px,95vw)",
                margin: "2rem auto",
            }}
        >
            <TileHeader>
                <Heading
                    size="sm"
                    style={{
                        color: "#fff",
                        backgroundColor: "#62BA38",
                        padding: "0.9rem 8px",
                        fontWeight: "bold",
                        margin: "0",
                        borderTopRightRadius: "9px",
                        borderTopLeftRadius: "9px",
                    }}
                    textCase="capitalize"
                >
                    Gift Voucher Form
                </Heading>
            </TileHeader>
            {route.voucher && (
                <VoucherForm
                    form={form}
                    formError={formError}
                    formHandler={formHandler}
                    amountErrText={amountErrText}
                    product={product}
                    route={route}
                    setRoute={setRoute}
                    voucherFormHandler={voucherFormHandler}
                    voucherTypeOptions={voucherTypeOptions}
                    isProductVoucher={isProductVoucher}
                    valueVoucher={valueVoucher}
                    productVoucher={productVoucher}
                />
            )}
            {route.customer && (
                <CustomerForm
                    form={form}
                    setForm={setForm}
                    formError={formError}
                    formHandler={formHandler}
                    purchaserEmailErrText={purchaserEmailErrText}
                    recipientEmailErrText={recipientEmailErrText}
                    route={route}
                    setRoute={setRoute}
                    handleSubmit={handleSubmit}
                    debounceEmailHandler={debounceEmailHandler}
                    submitStatus={submitStatus}
                />
            )}
        </Tile>
    );
};

export default Form;
