import React from "react";
import { TileBody, DropdownField, Label, ErrorText, NumberField, Button } from "@clevero/components";

import { FieldGroup } from "../../Components.styled";

const VoucherForm = ({
    form,
    formError,
    formHandler,
    amountErrText,
    product,
    voucherFormHandler,
    isProductVoucher,
    valueVoucher,
    productVoucher,
}) => {
    const voucherOptions = [
        { value: productVoucher, label: "Product Voucher" },
        { value: valueVoucher, label: "Value Voucher" },
    ];
    const productOptions = product
        ? product.map(productItem => {
              return {
                  value: productItem,
                  label: productItem["product-name"],
              };
          })
        : [];

    return (
        <TileBody>
            <FieldGroup>
                <div>
                    <Label label="Voucher Type" name="voucherType" mandatory />
                    <DropdownField
                        name="voucherType"
                        placeholder="Choose voucher type"
                        options={voucherOptions}
                        value={form.voucherType}
                        onChange={formHandler}
                        error={formError["voucherType"]}
                        color="#62BA38"
                        disabled={isProductVoucher}
                        isLoading={!product}
                    />
                    <ErrorText error={formError["voucherType"]} errorText="This field is required" />
                </div>
                <div>
                    <Label
                        label="Product"
                        name="product"
                        mandatory={isProductVoucher ? true : form.voucherType === "Product Voucher"}
                    />
                    <DropdownField
                        name="product"
                        placeholder="Choose product"
                        loading={product}
                        options={productOptions}
                        value={form.product}
                        onChange={formHandler}
                        error={formError["product"]}
                        color="#62BA38"
                        disabled={isProductVoucher ? true : form.voucherType === "Value Voucher"}
                        isLoading={!product}
                    />
                    <ErrorText error={formError["product"]} errorText="This field is required" />
                </div>
                <div>
                    <Label label="Amount" name="amount" mandatory />
                    <NumberField
                        name="amount"
                        value={form.amount}
                        onChange={formHandler}
                        error={formError["amount"]}
                        color="#62BA38"
                        min="0"
                        hideControls={true}
                        disabled={isProductVoucher ? true : form.voucherType === "Product Voucher"}
                    />
                    <ErrorText error={formError["amount"]} errorText={amountErrText || `This field is required`} />
                </div>
            </FieldGroup>

            <div
                style={{
                    display: "flex",
                    width: "100%",
                    marginTop: "1rem",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    className="btn"
                    size="md"
                    color="#62BA38"
                    onClick={voucherFormHandler}
                    style={{
                        display: "inline-block",
                    }}
                >
                    Next
                </Button>
            </div>
        </TileBody>
    );
};

export default VoucherForm;
