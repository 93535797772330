/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";
import { useNavigate } from "react-router-dom";

import { AvailabilityStatus, ContinueButton, EnquiryButton, EventStartEnd } from "../Components.styled";
import createMarkup from "../../Utility/parseHTML";

//----------------------Styles----------------------//

const GreenLine = tw.div`h-1 w-9 bg-LAA-primary rounded-md`;
const GreenCircle = tw.span`h-1 w-1 rounded-full bg-LAA-primary`;

const MainContainer = styled.main(props => [
    tw`max-h-screen overflow-y-auto bg-white rounded-3xl relative flex flex-row`,
    `width: min(66rem, 96vw);`,
]);

const DetailsSection = styled.section(props => [
    tw`flex flex-col gap-2 bg-white w-full md:w-[60%]`,
    `padding: 1.5rem min(3rem, 6vw);`,
]);
//----------------------Styles----------------------//

//-------------------------------/Main Component here/-------------------------------//
const EventDetails = props => {
    const navigate = useNavigate();

    const html = props.data.modalImageHtml;
    const url = html.match(/<img [^>]*src="[^"]*"[^>]*>/gm).map(x => x.replace(/.*src="([^"]*)".*/, "$1"))[0];

    const lowestPrice = props.data.sortedPrice[0].value
        .toLowerCase()
        .replace(" price", "")
        .concat(" price")
        .replaceAll(" ", "-");

    return (
        <MainContainer>
            <DetailsSection>
                <div tw="text-4xl font-bold leading-normal text-gray-600 mt-4">
                    {props.data.productName}&nbsp;
                    <AvailabilityStatus
                        color={props.data.colorCode}
                        renderComponent={!!props.data.tag}
                        bookingHeader
                        alignSub
                    >
                        {props.data.tag}
                    </AvailabilityStatus>
                </div>

                <div>
                    <div tw="text-gray-600 text-base font-semibold">Description</div>
                    <div
                        tw="max-h-32 my-2 overflow-y-auto text-justify text-sm text-gray-600 border-2 border-gray-200 py-2 px-4 bg-[#fafafa] rounded-lg"
                        dangerouslySetInnerHTML={createMarkup(props.data.description)}
                    />
                </div>

                <div tw="flex justify-between my-2">
                    <div tw="flex flex-col gap-2">
                        <div tw="flex items-center gap-2 text-2xl font-semibold text-gray-700">
                            {props.data.duration < 10 ? "0" + props.data.duration : props.data.duration}
                            <GreenLine />
                        </div>

                        <div tw="flex items-center gap-2">
                            <span tw="font-semibold text-gray-700">Days</span>
                            <GreenCircle />
                            <span tw="font-normal text-gray-500">Duration</span>
                        </div>
                    </div>

                    <div tw="flex flex-col gap-2">
                        <div tw="flex items-center gap-2 text-2xl font-semibold text-gray-700">
                            <span tw="font-semibold text-base uppercase text-gray-700">From</span>
                            {(+props.eventDetails[lowestPrice]).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                            })}
                            <GreenLine />
                        </div>

                        <div tw="flex items-center gap-2">
                            <span tw="font-semibold text-gray-700">AUD</span>
                            <GreenCircle />
                            <span tw="font-normal text-gray-500">{props.data.sortedPrice[0].value}</span>
                        </div>
                    </div>
                </div>

                <EventStartEnd data={props.data} />

                {props.data.variedItinerary && (
                    <div>
                        <div tw="text-base font-semibold text-LAA-red">Varied Itinerary *</div>
                        <div
                            tw="max-h-28 my-2 overflow-y-auto text-justify text-sm text-gray-600 border-2 border-gray-200 py-2 px-4 bg-[#fafafa] rounded-lg"
                            dangerouslySetInnerHTML={createMarkup(props.data.variedItineraryDetails)}
                        />
                    </div>
                )}

                <div tw="flex justify-end py-4">
                    {props.isEnquirable ? (
                        <EnquiryButton />
                    ) : (
                        <ContinueButton
                            onClick={() =>
                                navigate("/booking/guest-details", {
                                    state: {
                                        eventDetails: props.eventDetails,
                                        parsedEventDetails: props.data,
                                    },
                                })
                            }
                        />
                    )}
                </div>
            </DetailsSection>

            <aside
                tw="w-[40%] bg-red-300 bg-cover relative items-center hidden md:flex"
                style={{
                    backgroundImage: url ? `url(${url})` : `url(https://source.unsplash.com/1HDyLnA3g8k)`,
                }}
            >
                <div tw="h-full w-5 bg-white rounded-r-3xl items-stretch" />

                <div
                    tw="h-[30.4rem] w-[20rem] rounded-[9.2rem] bg-red-100 bg-cover m-auto"
                    style={{
                        backgroundImage: url ? `url(${url})` : "url(https://source.unsplash.com/KNLuLVloHuo)",
                    }}
                />
            </aside>
        </MainContainer>
    );
};
export default EventDetails;
