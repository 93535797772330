import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

import GuestTable from "./GuestTable";
import UpdateGuestForm from "./UpdateGuestForm";
import AddGuestForm from "./AddGuestForm";
import BookingId from "./BookingId";
import NavigationButtons from "../../NavigationButtons";

import Modal from "../../../Modal";
import { checkExistingGuest, useGetSelectData } from "../../../../api/tours.api";

const GuestDetailsMain = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const toastId = useRef(null);
    const navigationBtn = useRef(null);
    const ModalRef = useRef(null);
    const [guestIdx, setGuestIdx] = useState(null);
    const { guestsArray, setGuestsArray, validBookingId, disableBtn, setDisableBtn } = useOutletContext();

    function disableAllBtns() {
        navigationBtn.current.disableNavigation();
        setDisableBtn(true);
    }

    function enableAllBtns() {
        navigationBtn.current.enableNavigation();
        setDisableBtn(false);
    }

    useEffect(() => {
        document.body.style.overflow = "unset";
    }, []);

    // const { data: priceTypes } = useGetSelectData({
    //   internalId: "laa-price-type-131-1624096545451",
    // });

    const { data: gender } = useGetSelectData({
        internalId: "laa-gender-131-1627995135915",
    });

    const newPriceType =
        location.state?.eventDetails &&
        location.state.eventDetails.priceTypes.filter(({ value }) => {
            const priceInternalId = value.split(" ");
            let newPriceInternalId = priceInternalId.filter(p => p.toLowerCase() !== "price");
            newPriceInternalId = [...newPriceInternalId, "price"];
            const internalId = newPriceInternalId.map(n => n.toLowerCase()).join("-");
            if (location.state?.eventDetails && location.state.eventDetails[internalId])
                return location.state.eventDetails[internalId] !== "0";
            return false;
        });

    const priceTypesObj = newPriceType?.reduce((prev, curr) => {
        const priceInternalId = curr.value.split(" ");
        let newPriceInternalId = priceInternalId.filter(p => p.toLowerCase() !== "price");
        newPriceInternalId = [...newPriceInternalId, "price"];
        const internalId = newPriceInternalId.map(n => n.toLowerCase()).join("-");
        return {
            ...prev,
            [curr.value]: {
                id: curr.id,
                label: curr.value,
                internalId,
                price: location.state?.eventDetails && location.state.eventDetails[internalId],
            },
        };
    }, {});

    function addGuest(newGuest) {
        //duplicate guest email check.
        // const isAdded = guestsArray.findIndex(guest => guest["email-address"] === newGuest["email-address"]);

        // if (isAdded !== -1) return toast.error("Guest already added!");

        setGuestsArray(prev => {
            const priceType = newGuest["price-type"];
            Object.assign(newGuest, {
                "deposit-required":
                    priceType === "Normal Price"
                        ? location.state.eventDetails.product["deposit-required"] || 200
                        : priceTypesObj[priceType].price,
                total: priceTypesObj[priceType].price,
            });
            if (newGuest["lead-guest"]) {
                const newArr = prev.map(obj => ({ ...obj, "lead-guest": false }));
                return [...newArr, newGuest];
            }
            return [...prev, newGuest];
        });
    }

    function updateLeadGuest(guestId) {
        setGuestsArray(prev => {
            const newArr = prev.map((obj, i) => {
                if (i === +guestId) return { ...obj, "lead-guest": true };
                return { ...obj, "lead-guest": false };
            });
            return newArr;
        });
    }

    function editGuest(guestId) {
        setGuestIdx(guestId);
        ModalRef.current.open();
    }

    function onEditGuest(editedGuest, guestIdx) {
        setGuestsArray(prev => {
            const priceType = editedGuest["price-type"];
            Object.assign(editedGuest, {
                "deposit-required":
                    priceType === "Normal Price"
                        ? location.state.eventDetails.product["deposit-required"] || 200
                        : priceTypesObj[priceType].price,
                total: priceTypesObj[priceType].price,
            });
            const newGuestArray = prev.map((guest, idx) => (idx === guestIdx ? editedGuest : guest));
            return newGuestArray;
        });
    }

    function deleteGuest(guestId) {
        setGuestsArray(prev => prev.filter((_, i) => i !== guestId));
    }

    async function onContinue() {
        disableAllBtns();

        if (!guestsArray.length) {
            toast.error("No guest added");
            return enableAllBtns();
        }

        if (guestsArray.findIndex(guestObj => guestObj["lead-guest"]) === -1) {
            toast.error("Please select a booking organizer");
            return enableAllBtns();
        }

        toastId.current = toast("Please wait...", {
            autoClose: false,
            closeButton: false,
            type: toast.TYPE.INFO,
        });

        const guestEmail = guestsArray.map(guest => ({
            email: guest["email-address"],
        }));

        const { guests, apiErr } = await checkExistingGuest({
            emails: guestEmail,
        }).catch(err => {
            console.log(err);
            return { apiErr: true };
        });

        if (apiErr) {
            enableAllBtns();
            return toast.update(toastId.current, {
                render: "An error occured",
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });
        }

        const updatedGuestArr = guestsArray.map(guestObj => {
            const guestMatch = guests.find(({ email }) => email === guestObj["email-address"]);
            if (guestMatch) {
                return {
                    ...guestObj,
                    isPastPassenger: guestMatch.isPastPassenger,
                    exists: guestMatch.guest.exists,
                    guestId: (guestMatch.guest.exists && guestMatch.guest.guestId) || null,
                };
            }
            return guestObj;
        });
        setGuestsArray(updatedGuestArr);

        enableAllBtns();
        toast.dismiss(toastId.current);
        navigate("/booking/discounts", {
            state: {
                priceTypesObj,
                guestsArray: updatedGuestArr,
                bookingId: validBookingId,
                eventDetails: location.state?.eventDetails,
                parsedEventDetails: location.state?.parsedEventDetails,
            },
        });
    }

    return (
        <>
            <Modal ref={ModalRef}>
                <UpdateGuestForm
                    priceTypes={newPriceType}
                    gender={gender}
                    guestIdx={guestIdx}
                    setGuestIdx={setGuestIdx}
                    guestsArray={guestsArray}
                    onEditGuest={onEditGuest}
                    ModalRef={ModalRef}
                />
            </Modal>

            <AddGuestForm
                guestsArray={guestsArray}
                location={location}
                addGuest={addGuest}
                priceTypes={newPriceType}
                gender={gender}
                disableBtn={disableBtn}
            />

            <GuestTable
                data={guestsArray}
                editGuest={editGuest}
                deleteGuest={deleteGuest}
                updateLeadGuest={updateLeadGuest}
            />

            <BookingId disableBtn={disableBtn} />
            <hr />

            <NavigationButtons ref={navigationBtn} onContinue={onContinue} />
        </>
    );
};

export default GuestDetailsMain;
