import tw, { styled } from "twin.macro";
import { useState } from "react";
import { toast } from "react-toastify";

import {
    Button,
    DropdownField,
    ErrorText,
    Label,
    PhoneField,
    RadioField,
    SimpleTile,
    TextField,
} from "@clevero/components";
import { BsCheck2Circle, BsDashCircle } from "react-icons/bs";

import { FieldWrapper, FieldGroup } from "../../../Components.styled";
import { bookingFormSchema, validateForm } from "../../../../Utility/schema.validator";

const TileWrapper = styled.div`
    width: min(46rem, 96vw);
`;
const FormHeader = tw.div`relative text-xl text-gray-500 font-medium mb-6 after:(content-[""] block mt-1 w-full h-0.5 bg-gradient-to-r from-gray-300 rounded-md)`;
const ButtonWrapper = tw.div`flex justify-between mt-6`;

const UpdateGuestForm = props => {
    //fields and its initial values
    const initialState = props.guestsArray[props.guestIdx];

    //field-value object
    const [fieldValues, setFieldValues] = useState(initialState);
    const [formError, setFormError] = useState({});

    //on update add
    async function onUpdateGuest() {
        //duplicate guest email check.
        // const isAdded = props.guestsArray.findIndex(
        //     (guest, idx) => props.guestIdx !== idx && guest["email-address"] === fieldValues["email-address"]
        // );

        // if (isAdded !== -1) {
        //     setFormError({
        //         "email-address": "Guest with this email already exists",
        //     });
        //     return toast.error("Guest already added!");
        // }

        const { error } = await validateForm(fieldValues, bookingFormSchema).catch(err => {
            setFormError(err);
            return { error: true };
        });
        if (error) return;

        //no form error
        props.onEditGuest(fieldValues, props.guestIdx);
        props.setGuestIdx(null);
        props.ModalRef.current.close();
        toast.success("Guest updated successfully!");
    }

    const fieldStyle = {
        background: "#F4F5F7",
        borderWidth: 0.6,
        borderFocus: "#62BA38",
        backgroundFocus: "none",
    };

    function onChangeHandler(e) {
        setFieldValues({ ...fieldValues, [e.name]: e.value });
    }

    function onFocusHandler(e) {
        setFormError(errObj => ({ ...errObj, [e.name]: null }));
    }

    const commonFieldProps = {
        onChange: onChangeHandler,
        onFocus: onFocusHandler,
        autoComplete: "off",
        fieldStyle,
        color: "#62BA38",
    };

    return (
        <TileWrapper>
            <SimpleTile innerStyle={{ padding: "min(5vw, 2rem)" }}>
                <FormHeader>Edit guest details</FormHeader>

                <FieldGroup>
                    <FieldWrapper>
                        <Label label="First Name" name={"first-name"} mandatory />
                        <TextField
                            name={"first-name"}
                            value={fieldValues["first-name"]}
                            error={formError["first-name"]}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["first-name"]} errorText={formError["first-name"]} />
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label label="Last Name" name={"last-name"} mandatory />
                        <TextField
                            name={"last-name"}
                            value={fieldValues["last-name"]}
                            error={formError["last-name"]}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["last-name"]} errorText={formError["last-name"]} />
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label label="Email Address" name={"email-address"} mandatory />
                        <TextField
                            name={"email-address"}
                            value={fieldValues["email-address"]}
                            error={formError["email-address"]}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["email-address"]} errorText={formError["email-address"]} />
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label label="Gender" name={"gender"} mandatory />
                        <RadioField
                            name={"gender"}
                            value={fieldValues["gender"]}
                            error={formError["gender"]}
                            options={props.gender
                                ?.map(({ id, value }) => ({
                                    label: value,
                                    value: id,
                                }))
                                .filter(({ label }) => label.toLowerCase() !== "other")}
                            isLoading={!props.gender}
                            loadingLines={3}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["gender"]} errorText={formError["gender"]} />
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label label="Phone Number" name={"phone-number"} mandatory/>
                        <PhoneField
                            name={"phone-number"}
                            value={fieldValues["phone-number"]}
                            error={formError["phone-number"]}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["phone-number"]} errorText={formError["phone-number"]} />
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label label="Price Type" name={"price-type"} mandatory />
                        <DropdownField
                            name={"price-type"}
                            value={fieldValues["price-type"]}
                            error={formError["price-type"]}
                            options={props.priceTypes?.map(({ value }) => ({
                                label: value,
                                value: value,
                            }))}
                            isLoading={!props.priceTypes}
                            {...commonFieldProps}
                        />
                        <ErrorText error={formError["price-type"]} errorText={formError["price-type"]} />
                    </FieldWrapper>
                </FieldGroup>

                <ButtonWrapper>
                    <Button
                        onClick={() => {
                            props.ModalRef.current.close();
                        }}
                        color="#FF0000"
                        variant="outline"
                        buttonStyle={{ borderWidth: 1 }}
                    >
                        <BsDashCircle />
                        &nbsp;Cancel
                    </Button>

                    <Button
                        onClick={() => onUpdateGuest()}
                        color="#62BA38"
                        variant="outline"
                        buttonStyle={{ borderWidth: 1 }}
                    >
                        <BsCheck2Circle />
                        &nbsp;Confirm
                    </Button>
                </ButtonWrapper>
            </SimpleTile>
        </TileWrapper>
    );
};

export default UpdateGuestForm;
