/** @jsxImportSource @emotion/react */
import { Routes, Route } from "react-router-dom";
// import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Iframe from "./components/Iframe";
import Calendar from "./components/Calendar/Calendar";
import BookingMain from "./components/Booking/BookingMain";
import GuestDetailsMain from "./components/Booking/Routes/GuestDetails/GuestDetailsMain";
import DiscountsMain from "./components/Booking/Routes/Discounts/DiscountsMain";
import CustomiseMain from "./components/Booking/Routes/Customise/CustomiseMain";
import Payments from "./components/Booking/Routes/Payment/Payments";
import NoPaymentSuccess from "./components/Booking/Routes/Payment/NoPaymentSuccess";
import Success from "./components/Booking/Routes/Payment/Success";
import VoucherPayment from "./components/Booking/Routes/Payment/VoucherPayment";
import GiftVoucherMain from "./components/GiftVoucherForm/GiftVoucherMain";

function App() {
    console.log(process.env, "---> ENV");
    return (
        <div>
            <Routes>
                <Route path="/" element={<Calendar />} />
                <Route path="/iframe" element={<Iframe />} />
                <Route path="/booking" element={<BookingMain />}>
                    <Route path="guest-details" element={<GuestDetailsMain />} />
                    <Route path="discounts" element={<DiscountsMain />} />
                    <Route path="Customise" element={<CustomiseMain />} />
                    <Route path="confirm-&-pay" element={<Payments />} />
                    <Route path="success" element={<NoPaymentSuccess />} />
                </Route>
                <Route path="/gift-voucher" element={<GiftVoucherMain />} />
                <Route path="/gift-voucher-payment" element={<VoucherPayment />} />
                <Route path="/success" element={<Success />} />
            </Routes>
            <ToastContainer position="bottom-right" />
            {/* {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      )} */}
        </div>
    );
}

export default App;
