import {
    addDays,
    addYears,
    format,
    eachMonthOfInterval,
    endOfMonth,
    endOfWeek,
    startOfDay,
    startOfMonth,
    startOfWeek,
    endOfYear,
    isSameYear,
    startOfYear,
    eachYearOfInterval,
    isWithinInterval,
} from "date-fns/esm";

//week generator//
export function takeWeek(startDate = new Date()) {
    let date = startOfWeek(startOfDay(startDate));

    return function () {
        const week = [...Array(7)].map((_, i) => addDays(date, i));
        date = addDays(date, 7);
        return week;
    };
}

//month generator//
export function takeMonth(startDate = new Date()) {
    let month = [];
    let date = startDate;

    const lastDayOfRange = range => range[range.length - 1][6];

    return function () {
        const weekGen = takeWeek(startOfMonth(date));
        const endDate = startOfDay(endOfWeek(endOfMonth(date)));
        month.push(weekGen());

        while (lastDayOfRange(month) < endDate) {
            month.push(weekGen());
        }

        const range = month;
        month = [];
        date = addDays(lastDayOfRange(range), 1);

        return range;
    };
}

//for rounded border style on calendar//
export function roundCorner(month, weekIndex, dayIndex) {
    if (!(weekIndex === 0 || weekIndex === month.length - 1)) return {};
    if (!(dayIndex === 0 || dayIndex === 6)) return {};
    if (weekIndex === 0 && dayIndex === 0) return { topLeft: true };
    if (weekIndex === 0 && dayIndex === 6) return { topRight: true };
    if (weekIndex === month.length - 1 && dayIndex === 0) return { bottomLeft: true };
    if (weekIndex === month.length - 1 && dayIndex === 6) return { bottomRight: true };
}

//returns list of months for dropdown//
export function calcMonthList(startDate = new Date(), endDate = new Date()) {
    function getMonthList(start, end) {
        const dateRange = eachMonthOfInterval({
            start,
            end,
        });

        return dateRange
            .map(date => format(date, "MMMM"))
            .map(month => ({
                label: month,
                value: month,
            }));
    }

    //returns startDate to end of startDate//
    if (isSameYear(startDate, endDate)) return getMonthList(startDate, endOfYear(startDate));

    //returns start of endDate to end of endDate//
    return getMonthList(startOfYear(endDate), endOfYear(endDate));
}

//returns list of year for dropdown//
export function calcYearList(startDate = new Date(), endDate = new Date(), range = 2) {
    function getYearList(start, end, range) {
        const dateRange = eachYearOfInterval({
            start,
            end: addYears(end, range),
        });

        return dateRange
            .map(date => format(date, "yyyy"))
            .map(year => ({
                label: year,
                value: year,
            }));
    }

    if (
        isWithinInterval(endDate, {
            start: startDate,
            end: addYears(startDate, range),
        })
    ) {
        return getYearList(startDate, startDate, 5);
    }

    return getYearList(startDate, endDate, range);
}

export const monthMapping = {
    january: 1,
    february: 2,
    march: 3,
    april: 4,
    may: 5,
    june: 6,
    july: 7,
    august: 8,
    september: 9,
    october: 10,
    november: 11,
    december: 12,
};
