import { styled } from "twin.macro";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import BookingHeader from "./BookingHeader";
import ProgressBar from "./ProgressBar";

const MainContainer = styled.main`
  width: min(64rem, 98vw);
  margin: auto;
`;

const BookingMain = () => {
  const location = useLocation();
  const barRef = useRef(null);
  const [guestsArray, setGuestsArray] = useState([]);
  const [discountedGuestsArray, setDiscountedGuestsArray] = useState([]);
  const [validBookingId, setValidBookingId] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnText, setBtnText] = useState("Join");

  useEffect(() => {
    const route = location.pathname.replace("/booking/", "");

    if (route === "success") {
      barRef.current.toStep(6);
      return;
    }
    barRef.current.toStep(null, route);
  }, [location.pathname]);

  return (
    <MainContainer>
      <BookingHeader />
      <hr />

      <ProgressBar ref={barRef} />
      <hr />

      <Outlet
        context={{
          guestsArray,
          setGuestsArray,
          discountedGuestsArray,
          setDiscountedGuestsArray,
          validBookingId,
          setValidBookingId,
          disableBtn,
          setDisableBtn,
          btnText,
          setBtnText,
          barRef,
        }}
      />
    </MainContainer>
  );
};

export default BookingMain;
