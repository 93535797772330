import Axios from "axios";
import { API_URL } from "../constants";

export const axiosInstance = Axios.create({
    baseURL: process.env.NODE_ENV === "development" ? API_URL : process.env.REACT_APP_API_URL,
    timeout: 20000,
});

axiosInstance.interceptors.response.use(
    function (response) {
        // console.log("response :", response);
        return response.data;
    },
    function (error) {
        throw error.response;
    }
);
