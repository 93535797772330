/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";
import { useRef } from "react";
import get from "lodash/get";
import { format, isSameDay, isSameMonth } from "date-fns/esm";

import Modal from "../Modal";
import EventDetails from "./EventDetails";
import { AvailabilityStatus } from "../Components.styled";

//----------------------Styles----------------------//
const DayContainer = styled.div(({ color, renderDate, roundCorner, selectedDate }) => [
    tw`relative transition-all overflow-hidden grid grid-cols-3 grid-rows-[2rem 1fr 2rem] items-center gap-y-2 p-2 hover:(outline-[2px solid #639A33] z-10)`,
    color && `&:hover{outline: 2px outset ${color};}`,

    (isSameMonth(renderDate, selectedDate) && tw`bg-white`) || tw`bg-gray-100`,

    roundCorner.topLeft && tw`rounded-tl-xl`,
    roundCorner.topRight && tw`rounded-tr-xl`,
    roundCorner.bottomRight && tw`rounded-br-xl`,
    roundCorner.bottomLeft && tw`rounded-bl-xl`,
]);
DayContainer.defaultProps = { className: "group" };

//------------on-container-hover------------//
DayContainer.OnHover = styled.div(({ color }) => [
    tw`absolute h-full w-full bg-LAA-red hidden justify-center items-center group-hover:(flex)`,

    color && `background-color: ${color}`,
]);

const Button = styled.div(({ color }) => [
    tw`bg-white uppercase transition text-center py-2 px-4 mx-4 rounded-md whitespace-nowrap shadow-md cursor-pointer hover:(brightness-75 text-black)`,

    (color && `color: ${color}; &:hover{background-color: ${color};}`) || tw`text-gray-800 hover:bg-gray-500`,

    // isEnquirable && tw`pointer-events-none text-black bg-gray-400`,
]);

const ButtonContent = tw.span`brightness-50 saturate-200 text-base`;

DayContainer.DayIcon = styled.span(({ color, currentDate, hasData, isEnquirable, renderDate, selectedDate }) => [
    tw`rounded-full h-[1.875rem] w-[1.875rem] flex items-center justify-center font-semibold z-10`,

    (color && `color: ${color}; text-decoration: underline;`) || tw`text-gray-800`,

    isSameDay(renderDate, currentDate) && tw`bg-LAA-primary-dim text-white`,

    color &&
        isSameDay(renderDate, currentDate) &&
        `background-color: ${color}; text-decoration: underline; color: #fff;`,

    !isSameMonth(renderDate, selectedDate) && tw`opacity-30`,

    hasData &&
        ((!isEnquirable && tw`group-hover:(text-white bg-transparent)`) || tw`group-hover:(text-black bg-transparent)`),
]);
//------------on-container-hover------------//

const DisplayWeek = props =>
    props.weekIndex === 0 && <span tw="col-start-3 col-span-1 justify-self-end font-bold">{props.children}</span>;
//----------------------Styles----------------------//

//-------------------------------/Main Component here/-------------------------------//
function DayComponent(props) {
    //Check and map data from api//
    const data = {
        productName: get(props.data, ["0", "product", "product-name"], null),
        description: get(props.data, ["0", "product", "tour-summary"], "").replace(/(<([^>]+)>)/gi, ""),
        tag: get(props.data, ["0", "availability-status", "tag"], null),
        colorCode: get(props.data, ["0", "availability-status", "colorCode"], null),
        sortedPrice: get(props.data, ["0", "priceTypes"], []).sort((priceTypeA, priceTypeB) => {
            const priceTypeAKey = priceTypeA.value
                .toLowerCase()
                .replace(" price", "")
                .concat(" price")
                .replaceAll(" ", "-");
            const priceTypeBKey = priceTypeB.value
                .toLowerCase()
                .replace(" price", "")
                .concat(" price")
                .replaceAll(" ", "-");
            return +props.data[0][priceTypeAKey] - +props.data[0][priceTypeBKey];
        }),
        startDate: get(props.data, ["0", "start-date"], null),
        endDate: get(props.data, ["0", "end-date"], null),
        duration: get(props.data, ["0", "product", "number-of-days"], null),
        variedItineraryDetails: get(props.data, ["0", "varied-itinerary-details"], null),
        variedItinerary: get(props.data, ["0", "varied-itinerary"], false),
        modalImageHtml: get(props.data, ["0", "product", "pre-departure-tour-photos"], "").replaceAll("\\", ""),
    };

    const hasData = !!props.data;
    const isEnquirable = data.tag && data.tag.toLowerCase() === "enquire";

    //Modal Ref//
    const ModalRef = useRef();

    //on "Book now" btn click//
    function onClickHandler() {
        //avoid opening modal if event is on another month//
        //same month filter checked since data is only available for the same month//
        if (props.data) ModalRef.current.open();
    }

    const { data: dummy, ...propsExceptData } = props;

    return (
        <>
            <DayContainer {...propsExceptData} color={data.colorCode}>
                <DayContainer.DayIcon
                    {...propsExceptData}
                    color={data.colorCode}
                    isEnquirable={isEnquirable}
                    hasData={hasData}
                >
                    {format(props.renderDate, "d")}
                </DayContainer.DayIcon>

                <DisplayWeek weekIndex={props.weekIndex}>{format(props.renderDate, "iii")}</DisplayWeek>

                <div tw="col-span-full line-clamp-2">{data.productName}</div>

                <div tw="col-span-2">
                    <AvailabilityStatus color={data.colorCode} renderComponent={!!data.tag}>
                        {data.tag}
                    </AvailabilityStatus>
                </div>

                {hasData && (
                    <DayContainer.OnHover color={data.colorCode}>
                        <Button color={data.colorCode} onClick={() => onClickHandler(data)}>
                            <ButtonContent>{(isEnquirable && "Enquire") || "book now"}</ButtonContent>
                        </Button>

                        {!isEnquirable && !!data.tag && (
                            <span tw="absolute top-3/4 text-white text-sm">{data.tag}</span>
                        )}
                    </DayContainer.OnHover>
                )}
            </DayContainer>

            {hasData && (
                <Modal ref={ModalRef}>
                    <EventDetails isEnquirable={isEnquirable} data={data} eventDetails={props.data && props.data[0]} />
                </Modal>
            )}
        </>
    );
}

export default DayComponent;
