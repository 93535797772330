import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";

import "tailwindcss/dist/base.min.css";
import { GlobalStyles } from "twin.macro";

const queryClient = new QueryClient();

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <React.StrictMode>
            <Router>
                <GlobalStyles />
                <App />
            </Router>
        </React.StrictMode>
    </QueryClientProvider>,
    document.getElementById("main-root")
);
